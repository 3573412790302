import { ClientConfigProvider } from '/machinery/ClientConfig'
import { reportClientError } from '/machinery/reportClientError'
import { ReportErrorProvider } from '/machinery/ReportError'

// eslint-disable-next-line @kaliber/no-default-export
export default function ClientWrapper({ children, ...props }) {
  const { clientContext } = props

  return (
    <ReportErrorProvider reportError={clientContext.reportError || reportClientError}>
      <ClientConfigProvider config={clientContext.clientConfig}>
        {children}
      </ClientConfigProvider>
    </ReportErrorProvider>
  )
}
