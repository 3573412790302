import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import styles from './Progressbar.css'

gsap.registerPlugin(ScrollTrigger)

export function Progressbar({ layoutClassName = undefined }) {
  const progressRef = React.useRef(null)

  useGSAP(() => {
    gsap.fromTo(
      progressRef.current,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
        ease: 'linear',
        scrollTrigger: {
          trigger: 'main',
          start: 'top top',
          end: 'bottom bottom',
          scrub: 1,
        }
      }
    )
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.layout}>
        <div  ref={progressRef} className={styles.inner} />
      </div>
    </div>
  )
}
